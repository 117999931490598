body,
h1 {
  margin: 0;
  padding: 0;
}

a{
  text-decoration: none;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

.container {
  padding: 2rem 1rem;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
  }
  
  70% {
    transform: scale(1);
  }
  
  100% {
    transform: scale(0.95);
  }
}

@keyframes pulse-white {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

.pulse {
  color: white;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
  animation: pulse 2s infinite;
}

.pulse.white {
  color: white;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
  animation: pulse-white 2s infinite;
}

.filter {
  background: rgba(14, 27, 79, 0.5);
  min-height: 24rem;
  position: relative;
  width: 100%;
  z-index: 50;
  padding: 6rem 0 2rem 0;
}

.flex-custom {
  display: flex;
  flex-direction: column;
  grid-gap: 1.5rem;
  justify-content: center;
  align-items: center;
}

header{
  padding: 0;
  margin: 0 0 3rem 0;
  color: #fff;
  min-height: 24rem;
  background-image: url("./img/header-background.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.header-logo {
  background-repeat: no-repeat;
  background-image: url("./img/DarkLogo.svg");
  background-size: contain;
  height: 12rem;
  margin: 6rem 0 0 4rem;
  width: 100%;
}

header h1{
  padding: 1rem 0 0 4rem;
  font-size: 2.2rem;
}

header p{
  margin-bottom: 0;
  padding:0 0 0 4rem;
  font-size: 1.1rem;
  font-weight: 300;
  max-width: 43rem;
}

.header-icon{
  margin-top: 3rem;
  display: block;
  text-align: center;
  color: #fff;
}

.header-icon svg{
  font-size: 2rem;
  border-radius: 50%;
}

.header-icon span{
  display: block;
  margin-top: 0.5rem;
  font-size: 0.8rem;
  padding-left: 0.3rem;
}

.navbar-brand{
  margin: 0;
}

.nav-logo{
  height: 2rem;
  width: 6rem;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("./img/DarkLogo.svg");
}

.navbar .nav-link{
  color: #fff;
  font-size: 0.85rem;
}

.nav-pills .nav-item .nav-link{
  width: 15rem;
  margin: 0 0.5rem;
  color: #29397A;
  border: 2px solid #29397A;
  border-radius: 0.4rem;
}

.nav-pills .nav-item .nav-link.active{
  background-color: #29397A;
  color: #fff;
}

.pluto-btn{
  font-size: 0.8rem;
  padding: 0.25rem 0.65rem;
}

.card-body{
  min-height: 14.5rem;
}

.card-body h3{
  color: #DA2F5C;
  font-size: 0.9rem;
  text-align: left;
}

.card-body p{
  text-align: justify;
}

.card-body a{
  text-align: left;
}

.pluto-img{
  height: 12rem;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0.4rem;
}

.left-img{
  background-position: top;
  background-image: url("./img/left_image.jpg");
}

.right-img{
  background-image: url("./img/right_image.png");
}

.pluto-metrics h4{
  color: #DA2F5C;
  font-size: 1.8rem;
  display: inline-block;
}

.pluto-metrics span{
  text-align: justify;
}

tr{
  padding: 0;
  box-shadow: 0 1px 4px #999;
  -webkit-box-shadow:  0 1px 4px #999;
  margin: 0.5rem 0.125rem;
  transition: all 0.2s ease-in-out;
  border: none;
  border-radius: 0.4rem;
  min-width: 12.5rem;
  color: #000;
}

table {
  border-spacing: 0;
  border-collapse: collapse
}

td{
  border: none;
}

th:not(first-of-type), table.dataTable tbody td:not(first-of-type){
  border-left: 1px solid #E6E9E6;
}

table.dataTable tbody th, table.dataTable tbody td:first-of-type  {
border-top-left-radius: 0.4rem;
border-bottom-left-radius: 0.4rem;
}

table.dataTable tbody th, table.dataTable tbody td:last-of-type  {
border-top-right-radius: 0.4rem;
border-bottom-right-radius: 0.4rem;
}

.dataTables_wrapper .dataTables_paginate .paginate_button + .paginate_button {
  margin-left: 3px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  padding: 0.3rem 0.4rem;
  border: 1px solid #c4c8d8;
  border-radius: 5px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.next {
  margin-left: 3px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.previous {
  margin-right: 3px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.next:hover {
  background-color: #e3e5ec;
  color: #0073a8 !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.previous:hover {
  background-color: #e3e5ec;
  color: #0073a8 !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  background: none;
  background-color: #e3e5ec;
  color: #0073a8 !important;
  border: 1px solid #c4c8d8;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dataTables_wrapper .dataTables_paginate a{
  border-radius: 0.4rem !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.current:focus {
  border: 0;
  background-image: none;
  background-color: #29397A;
  color: #fff !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled{
    opacity: .6;
    border: 1px solid #c4c8d8;
    cursor: not-allowed;
    color: #a5abc4;
}

table.dataTable thead th, table.dataTable thead td {
  border-bottom: none;
}

table.dataTable {
  border-spacing: 0 0.5rem;
}

table.dataTable,
.table.dataTable.no-footer {
  border: none;
}

.table td {
  border-top: none;
  vertical-align: middle;
}

.dataTables_wrapper .dataTables_filter input {
  margin-left: 0;
  border: 1px solid rgba(0,0,0,0.3);
  padding: 5px 10px;
  line-height: 1.539;
  color: #031b4e;
  border-radius: 0.2rem;
  width: 100%;
}

.alerts-table-group-header td {
  border-top: none;
  padding: 0.8rem 0 0 0 !important;
}

.dataTables_wrapper .dataTables_length label {
  margin-bottom: 0;
  margin-right: 0;
}

.dataTables_wrapper .dataTables_filter label {
  margin-bottom: 0;
  margin-left: 0;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:focus {
  background-color: #29397A;
}

.dataTables_wrapper select{
  border: 1px solid rgba(0,0,0,0.3);
  border-radius: 0.2rem;
}

.search, .advanced-search{
  margin: 2rem auto 0 auto;
  max-width: 1200px;
  padding: 0 2rem;
}

.search-panel {
  display: flex;
}

.search-panel__filters {
  flex: 1;
}

.search-panel__results {
  flex: 3;
}

/* .ais-SearchBox-submit{
  position: relative;
  right: auto;
  left: auto;
  height: 2.25rem;
  width: 8rem;
  margin: 0 0.25rem;
} */

.ais-Highlight-highlighted {
  color: inherit;
  font-size: inherit;
}

#searchbox {
  margin-bottom: 2rem;
  /* width: 85.5%; */
}

#pagination {
  margin: 2rem auto;
  text-align: center;
}

.bg-dark {
  background-color: #29397A !important;
}

.text-primary{
  color: #29397A !important;
}

.btn-primary{
  background-color: #29397A;
  border-color: #29397A;
}

.galleryGrid {
  display: grid;
  padding: 2rem;
  gap: 3rem;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
}

.ais-Hits-item{
  /* min-width: 9rem; */
  /* min-width: 33%; */
  /* margin: 0.25rem auto; */
  border-radius: 0.4rem;
  /* white-space:pre-wrap; */
}

.hit-item {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.hit-name{
  font-size: 1rem;
  font-weight: bold;
  word-wrap: break-word;
}

.hit-description{
  font-size: 0.8rem;
}

.ais-Pagination-item--selected .ais-Pagination-link {
  color: #fff;
  background-color: #29397A;
  border-color: #29397A;
}

.is-Breadcrumb-link, .ais-HierarchicalMenu-link, .ais-Menu-link, .ais-Pagination-link, .ais-RatingMenu-link {
  color: #29397A;
  transition: color .2s ease-out;
}

.CQ {
  display: inline-block;
  min-width: 1em;
  /* em unit */
  padding: .3em;
  /* em unit */
  font-size: 7px;
  text-align: center;
  background: #D15600;
  color: #fefefe;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #29397A;
}

.CW {
  display: inline-block;
  min-width: 1em;
  /* em unit */
  padding: .3em;
  /* em unit */
  font-size: 7px;
  text-align: center;
  background: #356AA0;
  color: #fefefe;
}

.footer-text{
  font-size: 0.7rem;
}

.footer-nav .nav-link{
  color: #29397A;
  font-size: 0.7rem;
  white-space: nowrap; 
  padding: 0.2rem;
}


@media (max-width: 768px) {
  .header-logo {
    height: 8rem;
    width: 80%;
    margin: 4rem auto 0 auto;
  }

  header h1{
    padding: 1rem 0 0.5rem 1rem;
  }
  
  header p{
    padding-left: 1rem;
  }

  .search, .advanced-search {
    padding: 0 0.5rem;
  }

  .ais-Hits-list{
    margin-left: 0;
    flex-direction: column;
  }

  .ais-Hits-item {
    border-radius: 0.4rem;
    margin-left: 0rem;
    width: 100%;
    text-align: center;
  }

  .hit-item {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .pluto-metrics span{
    text-align: center;
  }
}

@media (max-width: 768px) {
  .header-logo {
    height: 8rem;
    width: 80%;
    margin: 4rem auto 0 auto;
  }
}